<template>
  <full-screen-loading />
</template>
<script>
import FullScreenLoading from '@/components/common/FullScreenLoading.vue'

export default {
  components: { FullScreenLoading },
  data() {
    return {
      user: this.$auth.user,
    }
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['user/isUserAdmin']
    },
  },
  mounted() {
    this.callBack()
  },
  methods: {
    async callBack() {
      await this.$auth.handleRedirectCallback()
      await this.$auth.getTokenSilently()
      await this.$store.dispatch('user/setPackagesAvailable')
      await this.$store.dispatch('user/setAllUserGroupIfNotPresent')
      if (this.isUserAdmin) {
        await this.$router.push('/admin/applications')
      } else await this.$router.push('/phone-verification')
    },
  },
}
</script>
